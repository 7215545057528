export default () => {
   $(function () {
      var bsDefaults = {
         offset: false,
         overlay: true,
         width: '330px'
      },
      bsMain = $('.bs-offset-main'),
      bsOverlay = $('.bs-canvas-overlay');

      $('[data-toggle="canvas"][aria-expanded="false"]').on('click', function() {
      var canvas = $(this).data('target'),
         opts = $.extend({}, bsDefaults, $(canvas).data()),
         prop = $(canvas).hasClass('bs-canvas-right') ? 'margin-right' : 'margin-left';

      if (opts.width === '100%')
         opts.offset = false;

      $(canvas).css('width', opts.width);
      if (opts.offset && bsMain.length)
         bsMain.css(prop, opts.width);

      $(canvas + ' .bs-canvas-close').attr('aria-expanded', "true");
      $('[data-toggle="canvas"][data-target="' + canvas + '"]').attr('aria-expanded', "true");
      if (opts.overlay && bsOverlay.length)
         bsOverlay.addClass('show');
      return false;
      });

      $('.bs-canvas-close, .bs-canvas-overlay').on('click', function() {
      var canvas, aria;
      if ($(this).hasClass('bs-canvas-close')) {
         canvas = $(this).closest('.bs-canvas');
         aria = $(this).add($('[data-toggle="canvas"][data-target="#' + canvas.attr('id') + '"]'));
         if (bsMain.length)
            bsMain.css(($(canvas).hasClass('bs-canvas-right') ? 'margin-right' : 'margin-left'), '');
      } else {
         canvas = $('.bs-canvas');
         aria = $('.bs-canvas-close, [data-toggle="canvas"]');
         if (bsMain.length)
            bsMain.css({
               'margin-left': '',
               'margin-right': ''
            });
      }
      canvas.css('width', '');
      aria.attr('aria-expanded', "false");
      if (bsOverlay.length)
         bsOverlay.removeClass('show');
      return false;
      });
   });


   // Link to Tabbed Content
   /*$('.tab-anchor').on('click', function(e){
      let day = $(this).data('day');
      $tab = $('#' + day);
      if ($tab.hasClass('active')) {
          return;
      }
      switch (day) {
          case 'day-one':
              break;
          case 'day-two':
              $('#day-one').removeClass('active');
              $('#day-two').addClass('active');
              $('#day-three').removeClass('active');
              $('#day01').removeClass('active show');
              $('#day02').addClass('active show');
              $('#day03').removeClass('active show');
              break;
          case 'day-three':
            $('#day-one').removeClass('active');
            $('#day-two').removeClass('active');
            $('#day-three').addClass('active');
            $('#day01').removeClass('active show');
            $('#day02').removeClass('active show');
            $('#day03').addClass('active show');
              break;
          default:
              break;
      }
   });

   $(function() {
      $("a[data-tab-destination]").on('click', function(e) {
          e.preventDefault();
          // Works
          var tab = $(this).attr('data-tab-destination');

          $("#" + tab).click();

          // Doesn't work
          var hashtag = $(this.hash);

          var target = hashtag.length ? hashtag : $('[name=' + this.hash.slice(1) + ']');

          if (target.length) {
              $(".tab-pane").removeClass("fade").addClass("active");
              $('html, body').animate({scrollTop: target.offset().top}, 1000);
              return false;
          }
      });
   });*/



   // Back to top of page
   $(document).ready(function(){
      $(window).scroll(function(){
          if ($(this).scrollTop() > 100) {
              $('#btn-back-to-top').fadeIn();
          } else {
              $('#btn-back-to-top').fadeOut();
          }
      });
      $('#btn-back-to-top').click(function(){
          $("html, body").animate({ scrollTop: 0 }, 600);
          return false;
      });
  });
}

//Pagination
(function($){
   $.fn.customPaginate = function(options) {
      var paginationContainer = this,
         itemsToPaginate,
         numberOfPaginationLinks,
         unorderList,
      defaults = {
         itemsPerPage: 5
      },
      settings = {
      };
      $.extend(settings,defaults,options);
      var itemsPerPage = settings.itemsPerPage;
      itemsToPaginate = $(settings.itemsToPaginate); //.post
      numberOfPaginationLinks = Math.ceil((itemsToPaginate.length / itemsPerPage));
      $("<ul></ul>").prependTo(paginationContainer);
      unorderList = paginationContainer.find("ul");
      for(var i = 0; i < numberOfPaginationLinks; i++) {
         unorderList.append("<li>" + (i + 1) + "</li>");
      }
      itemsToPaginate.filter(":gt(" + (itemsPerPage - 1) + ")").hide();
      unorderList.children("li").eq(0).addClass("active");
      unorderList.children("li").on("click",function(){
         var linkNumber = $(this).text(),
               itemsToHide = itemsToPaginate.filter(":lt(" + ((linkNumber - 1) * itemsPerPage) + ")");
               $.merge(itemsToHide,itemsToPaginate.filter(":gt(" + ((linkNumber * itemsPerPage) - 1) + ")"));
               itemsToHide.hide();
               linkNumber = $(this);
               linkNumber.siblings().removeClass("active");
               linkNumber.addClass("active");
         var itemsToShow = itemsToPaginate.not(itemsToHide);
         itemsToShow.show();
      });
   };
}(jQuery));

// init pagination
(function($){
   $(document).ready(function(){
      $(".pagination").customPaginate({
         itemsToPaginate: ".post",
         itemsPerPage: 10
      });
   });
}(jQuery));
jQuery(document).on('click','.pagination', function() {
$('html, body').animate({ scrollTop: 0 }, 'fast');
});